import { type Placement, autoUpdate, computePosition, flip, offset, shift } from "@floating-ui/dom";
import { For, type ParentProps, type Signal, createEffect, createSignal, onCleanup, onMount } from "solid-js";
import { classNames } from "~/lib/classNames";
import type { useUIState } from "~/ui/UIState";
import styles from "./ContextMenu.module.css";
import { ContextMenuDivider } from "./ContextMenuDivider";
import { ContextMenuItem, type ContextMenuItemProps } from "./ContextMenuItem";
export interface ContextMenuProps extends ParentProps {
  id: string;
  signal: Signal<string>;
  placement: Placement;
  bound: HTMLElement;
  items: ContextMenuItemProps[];
  class?: string;
  offset?: number;
  strategy?: "fixed" | "absolute";
}

/**
 * Close overlays
 */
export const resetContextMenus = (state: ReturnType<typeof useUIState>) => {
  const [, setCollectionsContextMenu] = state.collectionsContextMenu;
  const [, setAccountContextMenu] = state.accountContextMenu;
  setCollectionsContextMenu("");
  setAccountContextMenu("");
};

export const ContextMenu = (props: ContextMenuProps) => {
  const [show, setShow] = props.signal;
  const [parentRef, setParentRef] = createSignal<HTMLElement | null>(null);
  const [buttons, setButtons] = createSignal<HTMLElement[]>([]);
  const [focusPosition, setFocusPosition] = createSignal(0);
  const [cleanup, setCleanup] = createSignal<(() => void) | null>(null);

  const updatePosition = () => {
    const parent = parentRef();
    if (!parent) return;

    computePosition(props.bound, parent, {
      strategy: props.strategy ?? "fixed",
      placement: "bottom-start",
      middleware: [offset(props.offset ?? 8), flip(), shift({ padding: 16 })],
    }).then(({ x, y }) => {
      Object.assign(parent.style, {
        left: `${x}px`,
        top: `${y}px`,
      });
    });
  };

  createEffect(() => {
    const parent = parentRef();
    if (!parent) return;

    if (show() === props.id) {
      props.bound.setAttribute("aria-expanded", "true");
      const cleanupFn = autoUpdate(props.bound, parent, updatePosition, { elementResize: false });
      setCleanup(() => cleanupFn);

      const button = buttons()[0];
      if (button) {
        button.focus();
      }
    } else {
      props.bound.setAttribute("aria-expanded", "false");
      const cleanupFn = cleanup();
      if (cleanupFn) {
        cleanupFn();
        setCleanup(null);
      }
    }
  });

  const onKeyDown = (event: KeyboardEvent) => {
    if (buttons().length === 0) return;

    if (event.key === "ArrowUp") {
      if (focusPosition() === 0) return;
      setFocusPosition(focusPosition() - 1);
      const button = buttons()[focusPosition()];
      if (button) button.focus();
    } else if (event.key === "ArrowDown") {
      if (focusPosition() === buttons().length - 1) return;
      setFocusPosition(focusPosition() + 1);
      const button = buttons()[focusPosition()];
      if (button) button.focus();
    } else if (event.key === "Escape") {
      setShow("");
      props.bound.focus();
      setFocusPosition(0);
    } else if (event.key === "Enter" || event.key === " ") {
      const button = buttons()[focusPosition()];
      if (button) {
        button.click();
        setShow("");
        props.bound.focus();
        setFocusPosition(0);
      }
    }
    event.stopImmediatePropagation();
    event.preventDefault();
  };

  const onClick = (event: Event) => {
    setShow(show() === props.id ? "" : props.id);
    const parent = parentRef();
    if (show() === props.id && parent) {
      parent.focus();
    } else {
      props.bound.focus();
    }
    event.preventDefault();
    event.stopImmediatePropagation();
  };

  onMount(() => {
    if (props.bound) {
      props.bound.addEventListener("click", onClick);
    }
    const parent = parentRef();
    if (parent) {
      setButtons(Array.from(parent.querySelectorAll("button")));
    }
    onCleanup(() => {
      if (props.bound && props.bound instanceof HTMLElement && typeof props.bound.removeEventListener === "function") {
        props.bound.removeEventListener("click", onClick);
      }
      const cleanupFn = cleanup();
      if (cleanupFn) {
        cleanupFn();
        setCleanup(null);
      }
    });
  });

  return (
    <menu
      class={classNames(
        styles["context-menu"],
        show() === props.id ? styles["context-menu--visible"] : "",
        props.class,
      )}
      ref={setParentRef}
      id={props.id}
      onKeyDown={onKeyDown}
    >
      <For each={props.items}>
        {(element) => (
          <>
            <ContextMenuItem {...element} />
            {element.divider === true ? <ContextMenuDivider /> : null}
          </>
        )}
      </For>
    </menu>
  );
};
